import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useContent } from "fusion-content";
import { useAppContext } from "fusion-context";
import get from "lodash.get";
import throttle from "lodash.throttle";
import { Header } from "@washingtonpost/site-header";
import { useWindowWidth } from "@washingtonpost/react-hooks";
import { styled } from "@washingtonpost/wpds-ui-kit";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import HeaderLogo from "./_children/HeaderLogo";
import { HOMEPAGE_URL } from "../../../layouts/homepage.helpers";
import contentFilter from "../../../../content/filters/site-subs-offer";
import navContentFilter from "../../../../content/filters/site-navigation";
import accountContentFilter from "../../../../content/filters/site-accountbar";
import { useMultiView } from "~/shared-components/MultiViewContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import { sm } from "~/components/utilities/breakpoints";
import { sendGAClick } from "../../../utilities/analytics/analytics";
import { useFeatureFlag } from "~/components/utilities/use-feature-flag.js";

const StyledHeader = styled(Header, {
  /**
   * NOTE: Shrink left most buttons' flex parent
   * when section links are on the page
   */
  "div[class*=variant-leftSide]": {
    "@media (min-width: 1149px)": {
      maxWidth: "fit-content"
    },
    // overrides wpds breakpoint
    "@media (max-width: 899px)": {
      flex: "unset",
      margin: `0 $050`,
      minWidth: "50px"
    }
  },
  "div[class*=variant-rightSide]": {
    "@media (min-width: 1149px)": {
      flexBasis: "fit-content",
      marginLeft: "100px"
    }
  },

  'a[data-qa="subs-offer"]': {
    "@md": {
      display: "none"
    }
  },
  '[data-qa="sc-account-button"]': {
    "@media (max-width: 899px)": {
      minWidth: "min-content"
    }
  }
});

const onAppOpen = () => {
  sendGAClick("onpage", "onpage-click-event", "banner-click");
};

const useHomePageNavData = () => {
  return useContent({
    source: "site-service-navigation",
    query: { hierarchy: "WebNavBar" },
    filter: navContentFilter
  });
};

export const useNavData = () => {
  const accountSideBarData = useContent({
    source: "site-account-sidebar",
    filter: accountContentFilter
  });

  const { globalContent } = useAppContext();

  // TODO do we need to have it fall back to something for homepage?
  const arcId = get(globalContent, "_id");

  const ctaOfferData = useContent({
    source: "site-homepage",
    filter: contentFilter
  }) || { site: {} };

  const sideNavData = useContent({
    source: "site-menu",
    query: { hierarchy: "WebNav" },
    filter: navContentFilter
  });

  const sideNavFeaturedData = useContent({
    source: "site-menu",
    query: { hierarchy: "WebNavFeatured" },
    filter: navContentFilter
  });

  if (sideNavData && accountSideBarData) {
    return {
      accountSideBarData,
      arcId,
      ctaOfferData,
      sideNavData,
      sideNavFeaturedData
    };
  }

  return null;
};

const handleCheckIfStuck = (ref) => {
  // targets the child `nav` element that has the border class
  const el = ref.current?.children?.[0];

  const callback = ([entry]) => {
    entry.target.classList.toggle("border-hide", entry.intersectionRatio >= 1);
  };

  // eslint-disable-next-line compat/compat
  const observer = new IntersectionObserver(callback, {
    rootMargin: "-1px 0px 0px 0px",
    threshold: 1
  });

  observer.observe(el);
};

const HPHeaderNav = () => {
  // site header expects an array featureFlag strings
  const serverSideFeatureFlags = [
    useFeatureFlag("darkModeInAppWebViews") ? "darkModeInAppWebViews" : "",
    useFeatureFlag("showThemeSelector") ? "showThemeSelector" : ""
  ];

  const { isAdmin } = useAppContext();
  const { isNoNav } = useAssemblerContext();
  const { isMultiView } = useMultiView();
  const breakpoints = useBreakpoints();
  const useScrollIf = (condition, listener) => {
    useEffect(() => {
      if (condition) {
        const scrollListener = throttle(listener, 20);
        window.addEventListener("scroll", scrollListener);
        return () => {
          window.removeEventListener("scroll", scrollListener);
        };
      }
      // eslint-disable-next-line react/display-name
      return () => null;
    }, [listener, condition]);
  };

  const useInlineLinkScrollState = (shouldListen) => {
    const [showLogo, hideInlineLinks] = useState(false);
    useScrollIf(shouldListen, () => {
      const currentScrollPosition = window.scrollY || 0;
      const scrollThreshold = 420;

      if (currentScrollPosition >= scrollThreshold) {
        hideInlineLinks(true);
      }

      if (currentScrollPosition <= scrollThreshold) {
        hideInlineLinks(false);
      }
    });
    return showLogo;
  };

  const [showComponent, setShowComponent] = React.useState(null);
  const [hasScrolled, setHasScrolled] = React.useState(false);

  /**
   * NOTE: useScrollState starts listening at
   * the small bp, which enables the subsequent
   * animations on the logo and subs buttons.
   */
  const width = useWindowWidth();
  const isSmallBreakpoint = width <= sm;

  const handleScroll = React.useCallback(() => {
    const currentScrollPosition = window.pageYOffset || 0;
    const scrollThreshold = 100;

    if (window.isExpressCheckoutOpen) {
      setShowComponent(false);
    } else if (currentScrollPosition >= scrollThreshold) {
      setHasScrolled(true);
      setShowComponent(true);
    } else {
      setShowComponent(false);
    }
  }, []);

  React.useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    if (isSmallBreakpoint) {
      window.addEventListener("scroll", handleScroll);
    }
  }, [handleScroll, isSmallBreakpoint]);

  const ref = useRef();

  useEffect(() => {
    return handleCheckIfStuck(ref);
  }, []);

  const data = useNavData();
  const inlineLinkData = useHomePageNavData();
  const hideLinks = !!breakpoints.bp && !breakpoints.lg && !breakpoints.mx;
  const showLogo = useInlineLinkScrollState(!!inlineLinkData) || hideLinks;
  if (!data) return null;
  if (isNoNav) return null;

  const {
    accountSideBarData,
    arcId,
    ctaOfferData,
    sideNavData,
    sideNavFeaturedData
  } = data;

  // The wrapper is used to ensure the nav's stickiness in the admin
  // since it does not want to have a parent div.
  return React.createElement(
    isAdmin ? `div` : React.Fragment,
    isAdmin
      ? { style: { transform: "translateY(0)", overflow: "hidden" } }
      : null,
    <StyledHeader
      arcId={arcId}
      adotData={{ selectedPromoKey: "hd_sub_promo" }}
      offerData={ctaOfferData}
      leftNavData={sideNavData}
      leftNavFeaturedData={sideNavFeaturedData}
      rightNavData={accountSideBarData}
      isFixed={false}
      isSticky={true}
      useProactiveSignIn={true}
      locationOnSite="homepage"
      onAppOpen={onAppOpen}
      showLogo={showLogo}
      multiview={isMultiView}
      serverSideFeatureFlags={serverSideFeatureFlags}
      // prevents border flashing on load
      className="border-hide"
      ref={ref}
    >
      {showLogo ? (
        <HeaderLogo
          hasScrolled={hasScrolled}
          showLogo={showLogo}
          showWpMark={showComponent}
          slogan="Democracy Dies in Darkness"
          url={HOMEPAGE_URL}
        />
      ) : (
        <React.Fragment />
      )}
    </StyledHeader>
  );
};

HPHeaderNav.propTypes = {
  data: PropTypes.object,
  isFixed: PropTypes.bool
};

export default HPHeaderNav;
