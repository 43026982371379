import PropTypes from "prop-types";
import React from "react";
import { keyframes, theme, styled } from "@washingtonpost/wpds-ui-kit";

import { WashingtonPost, WpMark } from "@washingtonpost/wpds-assets";

const Wrapper = styled("a", {
  textAlign: "center",
  position: "absolute",
  "@media (max-width: 899px)": {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    height: "24px"
  },
  variants: {
    showwpmark: {
      true: {
        "@media (max-width: 899px)": {
          position: "relative"
        }
      }
    }
  }
});

const fadeOut = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 }
});

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 }
});

const StyledWashingtonPost = styled(WashingtonPost, {
  width: "188px",
  display: "block",
  opacity: 1,
  visibility: "visible",
  backgroundColor: "transparent",
  animation: `${fadeIn} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
  // to keep contents from wiggling in ios mobile
  transform: "translate3d(0, 0, 0)",
  "@media (max-width: 899px)": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "160px"
  },
  variants: {
    hasScrolled: {
      true: {}
    },
    showLogo: {
      false: {
        "@media (max-width: 899px)": {
          opacity: 0,
          visibility: "hidden",
          animation: `${fadeOut} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
          pointerEvents: "none"
        }
      }
    },
    showwpmark: {
      true: {
        "@media (max-width: 899px)": {
          opacity: 0,
          animation: `${fadeOut} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
          pointerEvents: "none",
          display: "none"
        }
      },
      false: {}
    }
  },
  compoundVariants: [
    {
      hasScrolled: true,
      showwpmark: false,
      css: {
        "@media (max-width: 899px)": {
          opacity: 1,
          animation: `${fadeIn} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`
        }
      }
    }
  ]
});

const StyledWpMark = styled(WpMark, {
  width: theme.sizes["175"],
  height: "26px",
  // to keep contents from wiggling in ios mobile
  transform: "translate3d(0, 0, 0)",
  "@media (min-width: 900px)": {
    position: "relative",
    display: "none",
    visibility: "hidden"
  },
  variants: {
    showwpmark: {
      true: {
        "@media (max-width: 899px)": {
          position: "relative",
          opacity: 1,
          animation: `${fadeIn} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`
        }
      },
      false: {
        "@media (max-width: 899px)": {
          opacity: 0,
          animation: `${fadeOut} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
          pointerEvents: "none",
          display: "none"
        }
      }
    }
  }
});

const Slogan = styled("span", {
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes["075"],
  color: theme.colors.gray100,
  lineHeight: theme.lineHeights["100"],
  fontStyle: "italic",
  opacity: 1,
  display: "block",
  animation: `${fadeIn} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
  "@media (max-width: 899px)": {
    display: "none",
    opacity: 0,
    animation: `${fadeOut} 0.3s cubic-bezier(0.65, 0, 0.35, 1) 0s`
  }
});

const HeaderLogo = (props) => {
  const {
    hasScrolled,
    showLogo,
    showWpMark,
    slogan,
    url,
    variant = "secondary" // with white nav, the logo should be black now
  } = props;

  return (
    <React.Fragment>
      <Wrapper href={url} showwpmark={!!showWpMark}>
        <StyledWashingtonPost
          aria-label="The Washington Post homepage."
          color={variant}
          fill={variant}
          hasScrolled={hasScrolled} // should only animate after scrolling
          label="The Washington Post logo"
          showLogo={showLogo}
          showwpmark={!!showWpMark}
        />
        {hasScrolled && (
          <StyledWpMark
            color={variant}
            fill={variant}
            label="The Washington Post logo mark"
            showwpmark={!!showWpMark}
          />
        )}
        <Slogan aria-label="Tagline, Democracy dies in darkness">
          {slogan}
        </Slogan>
      </Wrapper>
    </React.Fragment>
  );
};

HeaderLogo.propTypes = {
  hasScrolled: PropTypes.bool,
  showLogo: PropTypes.bool,
  showWpMark: PropTypes.bool,
  slogan: PropTypes.string,
  url: PropTypes.string,
  /** Variant Choice */
  variant: PropTypes.oneOf(["primary", "outlined"])
};

export default HeaderLogo;
